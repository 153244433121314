//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 50%) !default; 	// #555
$gray-light:             lighten($gray-base, 70%) !default;   // #999
$gray-lighter:           lighten($gray-base, 96%) !default; 	// #eee
$gray-500:               #ededed;
$gray-400:               #ddd;

$brand-primary:         #000000;
$brand-chart:           #f8f8f8;
$brand-second-chart:    #151515;
$brand-second:          black;
$brand-success:         #669900;
$brand-info:            #00b0ff;
$brand-warning:         #ff9100;
$brand-danger:          #ff1744;
$brand-footer:          darken($brand-chart, 10%) !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #f8f8f8 !default;
//** Global text color on `<body>`.
$text-color:            $gray !default;
//** Global titles color.
$headings-color:        $gray-darker !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 20%) !default;
//** Link hover decoration.
$link-hover-decoration: none !default;
